import app from 'firebase/app'
import 'firebase/database';
// import moment from 'moment'
// import 'moment/locale/fi'

const config = {
  apiKey: "AIzaSyD1afnGu25d2lT8ZHZv0siH2Mf-3VFiTzE",
  authDomain: "c-hvs-t-2289918.firebaseapp.com",
  databaseURL: "https://c-hvs-t-2289918.firebaseio.com",
  projectId: "c-hvs-t-2289918",
  storageBucket: "c-hvs-t-2289918.appspot.com",
  messagingSenderId: "155447335521",
  appId: "1:155447335521:web:91b2c15b4df5a12a0d6820",
  measurementId: "G-DNP3KC7GRF"
}

app.initializeApp(config)
export const db = app.database()
