import React, { Component } from 'react'
import {
  Router,
  Route
} from 'react-router-dom'

/*
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
*/
// import Main from './Main'

import MainAdults from './MainAdults'
import MainU10 from './MainU10'
import MainU10Ip from './MainU10Ip'
import MainU18 from './MainU18'
import ReportU10 from './ReportU10'
import ReportU10Ip from './ReportU10Ip'
import ReportAdults from './ReportAdults'
import ReportU18 from './ReportU18'

import { createBrowserHistory } from 'history'

const hist = createBrowserHistory()

/*
/:onetimeid = hvs3, kesä 2020 aikuiset
/s21/:onetimeid = hvs4, syksy juniorit
/s21/a/:onetimeid = hvs5, syksy aikuiset
*/

class App extends Component {
  render () {
    console.log(this.props)
    return (
      <Router history={hist}>
        <Route exact path='/reps/a22/adults/:status' component={ReportAdults} />
        <Route exact path='/reps/a22/u10/:status' component={ReportU10} />
        <Route exact path='/reps/a22/u10ip/:status' component={ReportU10Ip} />
        <Route exact path='/reps/a22/u18/:status' component={ReportU18} />
        <Route exact path='/s22/a/:onetimeid' component={MainAdults} />
        <Route exact path='/s22/u10/:onetimeid' component={MainU10} />
        <Route exact path='/s22/u18/:onetimeid' component={MainU18} />
        <Route exact path='/s22/u10ip/:onetimeid' component={MainU10Ip} />
        {/* <Route exact path='/:onetimeid' component={Main} />  */}
      </Router>
    )
  }
}

export default App
