import React from 'react'
import ReactTable from 'react-table-6'
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import moment from 'moment'
import 'moment/locale/fi'
import { db } from './components/firebase'

const LEAF = 'hvsk223u10ip'

class ReportAdults extends React.Component {
  constructor(props) {
    super(props)
    this.buttonBlurRef = React.createRef()
    this.state = {
      loading: true,
      dataToDownload: []
    }
  }

  componentDidMount() {
    let noaccess = false
    if (window.location.hostname === 'hvsvahvistus.cintoia.com') {
      noaccess = true
    }
    const status = this.props.match.params.status
    let accept = false
    let wish = false
    let reject = false
    let wishmore = false
    if (status === 'accept') {
      accept = true
    }
    if (status === 'wish') {
      wish = true
    }
    if (status === 'reject') {
      reject = true
    }
    if (status === 'wishmore') {
      wishmore = true
    }
    db.ref('confirmdata/' + LEAF + '/persons/').on('value', (snapshot) => {
      if (snapshot.exists()) {
        let persons = snapshot.val()

        let keys = Object.keys(persons)
        let personarray = keys.map((k) => {
          if (!persons[k].status) {
            persons[k].status = 'noresponse'
            persons[k].statuschange = new Date(2035, 0, 1).getTime()
            
          } else if (persons[k].status === 'accept') {
            persons[k].tunnit = Object.keys(persons[k].hours).map((key) => {
              return persons[k].hours[key].status + ' ' + persons[k].hours[key].displaytext
            }).join('\n\r')
            if (persons[k].tunnit.indexOf('reject') > -1) {
              persons[k].status = 'reject'
            } else {
              persons[k].tunnit = ''
            }
          }
          return persons[k]
        })


        this.setState({
          persons: persons,
          loading: false,
          accept: accept,
          reject: reject,
          wish: wish,
          wishmore: wishmore,
          noaccess: noaccess,
          personarray,
        })
      } else {
        this.setState({
          persons: null,
          loading: false,
          accept: accept,
          reject: reject,
          wish: wish,
          wishmore: wishmore,
          noaccess: noaccess,
          personarray: []
        })
      }
    })
  }

  componentWillUnmount() {
    document.body.classList.toggle('login-page')
  }

  /*
  getRows = () => {
    const keys = Object.keys(this.state.persons)
    const rows = []
    let rownbr = 1
    for (let i = 0; i < keys.length; i++) {
      const p = this.state.persons[keys[i]]
      if (this.state.accept && p.status === 'accept') {
        rows.push(this.getRow(keys[i], rownbr))
        rownbr++
      } else if (this.state.wish && p.status === 'wish') {
        rows.push(this.getRow(keys[i], rownbr))
        rownbr++
      } else if (this.state.reject && p.status === 'reject') {
        rows.push(this.getRow(keys[i], rownbr))
        rownbr++
      } else if (
        !this.state.accept &&
        !this.state.wish &&
        !this.state.reject &&
        !this.state.wishmore &&
        !p.status
      ) {
        rows.push(this.getRow(keys[i], rownbr))
        rownbr++
      } else if (this.state.wishmore && p.additional) {
        rows.push(this.getRow(keys[i], rownbr))
        rownbr++
      }
    }
    return rows
  }

  getRow = (key, ind) => {
    const p = this.state.persons[key]
    const hKeys = Object.keys(p.hours)
    const rownbr = ind.toString()
    const hs = hKeys.map((k) => {
      const h = p.hours[k]
      if (h.status !== 'accept') {
        return (
          <div key={k}>
            <b>{h.status}</b>: {h.displaytext}
          </div>
        )
      } else {
        return <div key={k}>{h.status}</div>
      }
    })
    let age = 'Junior'
    if (p.generalpricing) {
      age = 'Adult'
    }
    if (
      !this.state.accept &&
      !this.state.wish &&
      !this.state.reject &&
      !this.state.wishmore
    ) {
      return (
        <tr key={key}>
          <td>{rownbr}</td>
          <td>{p.name}</td>
          <td>{age}</td>
          <td>{p.email}</td>
          <td>{p.emailsend}</td>
          <td>{p.status}</td>
          <td></td>
          <td>{p.added ? new Date(p.added).toString() : ''}</td>
          <td>{p.statuschange ? new Date(p.statuschange).toString() : ''}</td>
          <td>{hs}</td>
          <td></td>
          <td>{p.confirmkey}</td>
        </tr>
      )
    } else {
      return (
        <tr key={key}>
          <td>{rownbr}</td>
          <td>{p.name}</td>
          <td>{age}</td>
          <td>{p.email}</td>
          <td>{p.emailsend}</td>
          <td>{p.status}</td>
          <td>{p.hours}</td>
          <td>{p.added ? new Date(p.added).toString() : ''}</td>
          <td>{p.statuschange ? new Date(p.statuschange).toString() : ''}</td>
          <td>{hs}</td>
          <td>{p.message}</td>
          <td></td>
        </tr>
      )
    }
  }
*/
  
  getColumns = () => {
    let _cols =
    [
      {
        Header: 'Etunimi',
        accessor: 'firstname',
      },
      {
        Header: 'Lastnimi',
        accessor: 'lastname',
      },
      {
        id: 'added',
        Header: 'Added',
        accessor: (row) =>
          moment(new Date(row.added).toString())
            .format('dd DD.MM.YYYY HH:mm'),
        sortMethod: (a, b) => {
          var a1 = moment(a, 'dd DD.MM.YYYY HH:mm')
          var b1 = moment(b, 'dd DD.MM.YYYY HH:mm')
          if (a1 < b1) return 1
          else if (a1 > b1) return -1
          else return 0
        },
      },
      {
        Header: 'Yob',
        accessor: 'yob',
      },
      {
        Header: 'Group',
        accessor: 'mailtemplate',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Emaileja lähetetty',
        accessor: 'emailsend',
      },
      {
        Header: 'Hours',
        accessor: 'tunnit',
        style: { 'whiteSpace': 'unset' }
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) =>
              onChange(event.target.value)
            }
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option key={0} value=''>Kaikki</option>
            <option key={1} value='accept'>Hyväksyneet</option>
            <option key={2} value='reject'>Hylänneet</option>
            <option key={2} value='noresponse'>Ei vastausta</option>
            {/* ))} */}
          </select>
        ),
      },
        {
          id: 'statuschange',
          Header: 'Status update',
          accessor: (row) =>
            moment(new Date(row.statuschange).toString())
              .format('dd DD.MM.YYYY HH:mm'),
          sortMethod: (a, b) => {
            var a1 = moment(a, 'dd DD.MM.YYYY HH:mm')
            var b1 = moment(b, 'dd DD.MM.YYYY HH:mm')
            if (a1 < b1) return 1
            else if (a1 > b1) return -1
            else return 0
          },
        },
      {
        Header: 'Avain',
        accessor: 'confirmkey',
      },
      // {
      //   Header: 'Tunnit',
      //   accessor: 'actions',
      //   sortable: false,
      //   filterable: false,
      // },
      // {
      //   Header: 'Maksa',
      //   accessor: 'paymentactions',
      //   sortable: false,
      //   filterable: false,
      // },
    ]
    // if (!isAdmin) {
    //   _cols.splice((_cols.length-1), 1)
    // }
    // if (this.props.personal) {
    //   _cols.splice(0, 1)
    //   delete _cols[3].Footer
    // }
    return _cols
  }


  render() {
    if (!this.state.loading && !this.state.noaccess) {
      return (
        <div className='wrapper wrapper-full-page' ref='fullPages'>
          <Container className='themed-container' fluid={true}>
            
            {/* <Row>
              <Col className='ml-auto mr-auto' lg='12' md='12' sm='12'>
                <a href='/reps/accept'>Hyväksynyt</a> |{' '}
                <a href='/reps/wish'>Toivoo uutta tuntia</a> |{' '}
                <a href='/reps/reject'>Ei vastaanota</a> |{' '}
                <a href='/reps/nostatus'>Ei vahvistanut</a> |{' '}
                <a href='/reps/wishmore'>Toivoo lisää</a>
              </Col>
            </Row>
            <Row>
              <Col className='ml-auto mr-auto' lg='12' md='12' sm='12'>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nimi</th>
                      <th>Junior/Adult</th>
                      <th>Email</th>
                      <th>Emaileja lähetetty</th>
                      <th>Status</th>
                      <th>Added</th>
                      <th>Status update</th>
                      <th>Tunnit</th>
                      <th>Viesti</th>
                      <th>Avain</th>
                    </tr>
                  </thead>
                  <tbody>{this.getRows()}</tbody>
                </Table>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <ReactTable
                  data={this.state.personarray}
                  filterable
                  ref={this.reactTable}
                  onFilteredChange={this.onTableFilterChange}
                  columns={this.getColumns()}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100, 200, 400, 1000]}
                  defaultPageSize={100}
                  showPaginationTop
                  showPaginationBottom
                  className='-striped -highlight'
                  defaultSorted={[
                    {
                      id: 'statuschange',
                      desc: true
                    }
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </div>
      )
    } else {
      return (
        <div className='wrapper wrapper-full-page' ref='fullPages'>
          <div className='full-page section-image'>
            <div className='login-page'>
              <Container>
                <Row>
                  <Col className='ml-auto mr-auto' lg='4' md='6'>
                    <Card className='card-login'>
                      <CardHeader>
                        <Spinner size='sm' color='secondary' /> Loading...
                      </CardHeader>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <div
                className='full-page-background'
                style={{
                  backgroundImage: `url(${require('./assets/img/d.jpg')})`,
                }}
              />
            </div>
          </div>
        </div>
      )
    }
  }
}

export default ReportAdults
