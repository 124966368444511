import React from "react";
// import firebase from "components/firebase"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  Container,
  FormGroup,
  Label,
  Col,
  Row,
  Spinner
} from "reactstrap";

import background from './assets/img/d.jpg';

import { db } from './components/firebase'
/*
const demoPersons = {
  'bd291e04-407c-48ff-b4ee-9dec25339640': { name: 'Chuck Norris', status: 'accepted', email: 'faster.than@sound.in.space.com' },
  '1adafb63-1886-4f40-824a-d8f3f646640c': { name: 'Annika Wolin', status: null, email: 'annika.wolin@hvstennis.fi' },
  'fee959e1-9a06-4006-8283-e51faf9d4919': { name: 'David Bowie', status: null, email: 'ground.control@major.tom' },
  '079de286-79b6-44b0-9621-984564a777ce': { name: 'Pate Postimies', status: null, email: 'pate@postimies.ee' }
}

const demoData = [
  { id: 'woiqeuowqeuqwoeu', displaytext: 'Taivallahti, Lauantai, kenttä 1-3, klo 11.30-12.30 (alk. 17.8.2019, 20€/tunti)', },
  { id: '4oiqeuowqeuqwoeu', displaytext: 'Taivallahti, Lauantai, kenttä 1-3, klo 13.30-14.30 (alk. 17.8.2019, 20€/tunti)', }
]
*/
const timepassed = false
const MULTIPLE_HOURS_ACCEPT = 'Otan vastaan valitut valmennuspaikat'
const SINGLE_HOUR_ACCEPT = 'Otan vastaan valmennuspaikan'
const LEAF = 'hvsk223u18'
const TITLE1 = 'U18 kausi 2022-2023'
const TITLE2 = 'Peliajan vahvistus'

class MainAdults extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.buttonBlurRef = React.createRef()
    this.state = {
      terms: false,
      additional: false,
      hours: null,
      person: null,
      accepttext: '',
      loading: true
    }
  }

  onAccept = event => {
    this.onSubmit('accept', event)
  }

  onHope = event => {
    this.onSubmit('wish', event)
  }

  onNotAccept = event => {
    this.onSubmit('reject', event)
  }

  onSubmit = (status, event) => {
    event.preventDefault()
    let _p = this.state.person
    if(status === 'reject') {
      if(this.state.wish) {
        status = 'wish'
      }
    }
    _p.status = status

    let qs = {
      ot: this.props.match.params.onetimeid,
      pt: this.state.pt,
      status: status,
      additional: this.state.additional,
      message: this.state.message,
      hours: {},
      c: 10
    }

    this.state.person.hours.map((h) => {
      if (h.status) {
        qs.hours[h.id] = h.status
      }
    })
    fetch('https://us-central1-c-hvs-t-2289918.cloudfunctions.net/storeConfirmation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(qs),
    }).then(res => res.json())
      .then(
        (result) => {
          this.setState({ submitted: true, person: _p })
        },
        (error) => {
          this.setState({ submitted: false, person: _p })
        }
      )

  }

  setSelection = (id, value) => {
    let aCount = 0
    let wCount = 0
    this.state.person.hours.map((h) => {
      if (h.id === id) {
        h.status = value
      }
      if (h.status && h.status === 'accept') {
        aCount++
      }
      if (h.status && h.status === 'wish') {
        wCount++
      }
      // return h
    })

    let accepttext = SINGLE_HOUR_ACCEPT
    if (aCount > 1) {
      accepttext = MULTIPLE_HOURS_ACCEPT
    }
    let allReject = false
    if (aCount === 0) {
      allReject = true
    }
    let someWish = false
    if (wCount > 0) {
      someWish = true
    }
    this.setState({ accepttext: accepttext, reject: allReject, wish: someWish })
  }

  setAddional = value => {
    this.setState({ additional: value });
  }

  setMessage = value => {
    this.setState({ message: value });
  }

  acceptTerms = value => {
    this.setState({ terms: value });
  }

  componentDidMount() {
    document.body.classList.toggle("login-page")
    const confirmId = this.props.match.params.onetimeid
    db.ref('confirmdata/' + LEAF + '/index/' + confirmId).once('value').then((dataSnapshot) => {
      if (dataSnapshot.exists()) {
        let personId = dataSnapshot.val()
        db.ref('confirmdata/' + LEAF + '/persons/' + personId).once("value").then((snapshot) => {
          if (snapshot.exists()) {
            let person = snapshot.val()
            let keys = Object.keys(person.hours)
            const hoursData = keys.map((k) => {
              let d = person.hours[k]
              d.id = k
              return d
            })
            person.hours = hoursData
            let accepttext = SINGLE_HOUR_ACCEPT
            if (person.hours.length > 1) {
              accepttext = MULTIPLE_HOURS_ACCEPT
            }
    
            this.setState({
              person: person,
              pt: personId,
              loading: false,
              accepttext: accepttext
            })
          } else {
            this.setState({
              person: null,
              pt: null,
              loading: false
            })
          }
        })
      } else {
        window.location = '/s22/a/' + confirmId
      }
    })
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page")
  }

  createGeneralPricing = (general) => {
    if(general) {
    return (
      <Row>
      <Col className="border-bottom pb-2 pt-2" sm="12">
          Valmennuksen hinnasto <a href="https://hvstennis.fi/hinnasto/" target="_blank" rel="noreferrer">https://hvstennis.fi/hinnasto/</a><br/>
      </Col>
    </Row>
    )
    } else {
      return (
        <Row>
        <Col className="border-bottom pb-2 pt-2" sm="12">
          Valmennuksen hinnasto <a href="https://hvstennis.fi/hinnasto/" target="_blank" rel="noreferrer">https://hvstennis.fi/hinnasto/</a><br/>
        </Col>
      </Row>
      )
    }
  }

  createSelections = (submitted) => {
    return this.state.person.hours.map((data) => {
      if (submitted) {
        if (data.status === 'accept') {
          return (
            <Row key={data.id}>
              <Col sm="12">{data.displaytext}</Col>
            </Row>
          )
        } else {
          return (<Row key={data.id}></Row>)
        }
      } else {
        return (
          <Row key={data.id} className="border-bottom pb-2 pt-2" ><Col sm="12">
            {data.displaytext}<br />
            <Button
              color="success"
              outline
              type="button"
              size="sm"
              inline="true"
              active={data.status === 'accept'}
              onClick={e => this.setSelection(data.id, 'accept')}
            >
              Otan
                    </Button>
            <Button
              color="danger"
              outline
              type="button"
              size="sm"
              active={data.status === 'reject'}
              inline="true"
              onClick={e => this.setSelection(data.id, 'reject')}
            >
              En ota
                    </Button>
            {/* <Button
              color="info"
              outline
              type="button"
              size="sm"
              inline="true"
              active={data.status === 'wish'}
              onClick={e => this.setSelection(data.id, 'wish')}
            >
              En ota, toivon toista aikaa
                    </Button> */}

          </Col>

          </Row>)
      }
    })
    /*
              <FormGroup key={data.id} check inline>
                  <Input checked={data.accepted} type="checkbox" name={data.id} onChange={e => this.setSelection(e.target.id, e.target.checked)} /> <span className="form-check-sign" />
                  <Input checked={data.rejected} type="checkbox" name={data.id} onChange={e => this.setSelection(e.target.id, e.target.checked)} /> <span className="form-check-sign" />
                  <Input checked={data.wish} color="success" type="checkbox" name={data.id} onChange={e => this.setSelection(e.target.id, e.target.checked)} /> <span className="form-check-sign" />
                  <Label check>
                  {data.displaytext}
                </Label>
              </FormGroup>
    */
  }

  render() {
    if(timepassed) {
      return (
        <div className="wrapper wrapper-full-page" ref="fullPages"><div className="full-page section-image"><div className="login-page">
          <Container><Row><Col className="ml-auto mr-auto" lg="6" md="6"><Card className="card-login"><CardBody>
           Vahvistukset eivät enää on avoinna. Jos et vahvistanut harjoitusaikaasi, niin ota yhteyttä info@hvstennis.fi osoitteeseen kysyäksesi tilanteesta. 
          </CardBody></Card></Col></Row></Container>
          <div className="full-page-background" style={{ backgroundImage: "url(" + background + ")" }} />
        </div></div></div>
      )
    }
    if (!this.state.loading) {
      if (!this.state.person) {
        return (
          <div className="wrapper wrapper-full-page" ref="fullPages"><div className="full-page section-image"><div className="login-page">
            <Container><Row><Col className="ml-auto mr-auto" lg="6" md="6"><Card className="card-login"><CardBody>
              Henkilöä ei löydy. Tarkista linkki.
            </CardBody></Card></Col></Row></Container>
            <div className="full-page-background" style={{ backgroundImage: "url(" + background + ")" }} />
          </div></div></div>
        )
      }

      if (this.state.submitted) {
        if (this.state.person.status === 'accept') {
          return (
            <div className="wrapper wrapper-full-page" ref="fullPages"><div className="full-page section-image"><div className="login-page">
              <Container><Row><Col className="ml-auto mr-auto" lg="6" md="6"><Card className="card-login">
                <CardHeader>
                  <h3 className="header text-center">{TITLE1}<br />{TITLE2}</h3>
                </CardHeader>
                <CardBody>
                  <h5>Kiitos vahvistuksesta</h5>
                  Sinulle vahvistettu valmennusajat<br /><br />
                  <Row>
                    <Col className="checkbox-radios" sm="12">
                      {this.createSelections(true)}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col className="info" sm="12">
                      Löydät aina harjoitusajat <a href="https://www.tennisclub.fi/pelaaja/">Tennisclubista</a>. Mikäli sinulla ei ole vielä tunnuksia Tennisclubiin, niin saat ne sähköpostiin 3 vrk kuluessa.
                      {/* Vahvistusviesti on lähetetty sinulle myös sähköpostiin {this.state.person.email} */}
                    </Col>
                  </Row>
                </CardBody>
              </Card></Col></Row></Container>
              <div className="full-page-background" style={{ backgroundImage: "url(" + background + ")" }} />
            </div></div></div>
          )
        } else if (this.state.person.status === 'wish') {
          return (
            <div className="wrapper wrapper-full-page" ref="fullPages"><div className="full-page section-image"><div className="login-page">
              <Container><Row><Col className="ml-auto mr-auto" lg="6" md="6"><Card className="card-login">
                <CardHeader>
                  <h3 className="header text-center">{TITLE1}<br />{TITLE2}</h3>
                </CardHeader>
                <CardBody>
                  <h5>Kiitos vahvistuksesta</h5>
                  <br /><br />
            Jos pystymme järjestämään mahdollisuuden harjoitella toiseen aikaan, niin otamme yhteyttä heti.
            </CardBody>
              </Card></Col></Row></Container>
              <div className="full-page-background" style={{ backgroundImage: "url(" + background + ")" }} />
            </div></div></div>
          )
        } else { // reject
          return (
            <div className="wrapper wrapper-full-page" ref="fullPages"><div className="full-page section-image"><div className="login-page">
              <Container><Row><Col className="ml-auto mr-auto" lg="6" md="6"><Card className="card-login">
                <CardHeader>
                  <h3 className="header text-center">{TITLE1}<br />{TITLE2}</h3>
                </CardHeader>
                <CardBody>
                  <h5>Kiitos vahvistuksesta</h5>
                  <br /><br />
              Tervetuloa myöhemmin HVS-Tenniksen toimintaan mukaan. Aina päivittyvästä tarjonnasta löydät lisää tietoa osoitteesta <a href="https://www.hvstennis.fi">www.hvstennis.fi</a>.
              </CardBody>
              </Card></Col></Row></Container>
              <div className="full-page-background" style={{ backgroundImage: "url(" + background + ")" }} />
            </div></div></div>
          )

        }


      } else if (this.state.person.status && (this.state.person.status === 'accept' || this.state.person.status === 'reject' || this.state.person.status === 'wish')) {
        return (
          <div className="wrapper wrapper-full-page" ref="fullPages"><div className="full-page section-image"><div className="login-page">
            <Container><Row><Col className="ml-auto mr-auto" lg="6" md="6"><Card className="card-login"><CardBody>
              <h5 className="header text-center">
                Valmennuspaikan vahvistukseen on jo vastattu.<br /><br />Mikäli vastaanotit paikan löydät ajat <a href="https://www.tennisclub.fi/pelaaja/">Tennisclubista</a>
              </h5>
            </CardBody></Card></Col></Row></Container>
            <div className="full-page-background" style={{ backgroundImage: "url(" + background + ")" }} />
          </div></div></div>
        )
      }

      return (
        <div className="wrapper wrapper-full-page" ref="fullPages"><div className="full-page section-image"><div className="login-page">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="6" md="6">
                <Form action="" className="form" method="">
                  <Card className="card-login">
                    <CardHeader>
                      <h3 className="header text-center">{TITLE1}<br />{TITLE2}</h3>
                    </CardHeader>
                    <CardBody>
                      <h5>Hei {this.state.person.name}</h5>
                    Sinulle on annettu seuraavat valmennusajat<br /><br />
                      <Row>
                        <Col className="checkbox-radios" sm="12">
                          {this.createSelections(false)}
                        </Col>
                      </Row>
                      <br />
                      {this.createGeneralPricing(this.state.person.generalpricing)}
                      <br />
                      {this.state.wish && (
                      <Row>
                        <Col className="checkbox-radios" sm="12">
                          <FormGroup check>
                            <Label for="message">Aikatoive</Label>
                              <Input type="textarea" onChange={e => this.setMessage(e.target.value)} id="message" style={{ paddingLeft: '10px', paddingTop: '5px' }}/>
                          </FormGroup>
                        </Col>
                      </Row>
                      )}
                      {/* <Row>
                        <Col className="checkbox-radios" sm="12">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" onChange={e => this.setAddional(e.target.checked)} />
                              <span className="form-check-sign" />
                            Toivon, että minulle tarjotaan mahdollisia lisätunteja jos paikkoja ryhmissä vapautuu
                          </Label>
                          </FormGroup>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col>
                          <br/>
                         Huom. Valmentaja on miettinyt tarkkaan peliajat ja ryhmät, ja ottanut huomioon toiveet, jos mahdollista. Emme voi taata, että tilalle saa toisen peliajan. Jos peliaika ei kuitenkaan sovi, mutta toivot tilalle toisen ajan, hylkää peliaika yllä olevan linkin kautta ja laita sähköpostia info@hvstennis.fi. Muutos/lisäpyyntöihin vastataan elokuun lopussa, kun koulujen lukkarit ovat tulleet.
                          <br/>
                        </Col>
                      </Row>
                      {this.state.reject ?
                        <></> :
                        <Row>
                          <Col className="checkbox-radios pt-2" sm="12">
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox" onChange={e => this.acceptTerms(e.target.checked)} />
                                <span className="form-check-sign" />
                              Olen lukenut ja hyväksyn valmennuksen <a href="https://hvstennis.fi/tennisvalmennus/hvs-saannot-2022-2023/" target="_blank" rel="noreferrer" >säännöt</a>.
                            </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                    }
                    </CardBody>
                    <CardFooter>
                      {!this.state.reject && (
                        <Button
                          block
                          className="btn-round mb-3"
                          color="success"
                          onClick={this.onAccept}
                          disabled={!this.state.terms}
                          innerRef={this.buttonBlurRef}
                        >
                          {this.state.accepttext}
                        </Button>
                      )}
                      {this.state.reject && (
                        <Button
                          block
                          className="btn-round mb-3"
                          color={this.state.wish ? "info" : "danger"}
                          onClick={this.onNotAccept}
                          disabled={!this.state.reject}
                        >
                          Tallenna vahvistus
                        </Button>
                      )}

                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
          <div
            className="full-page-background"
            style={{
              backgroundImage: "url(" + background + ")"
            }}
          />
        </div>
        </div>
        </div>
      )
    } else {
      return (
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className="full-page section-image">
            <div className="login-page">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="4" md="6">
                    <Card className="card-login">
                      <CardHeader>
                        <Spinner size="sm" color="secondary" /> Loading...
                      </CardHeader>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <div
                className="full-page-background"
                style={{
                  backgroundImage: "url(" + background + ")"
                }}
              />
            </div>
          </div>
        </div>
      )
    }
  }
}

export default MainAdults;
